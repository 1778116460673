import * as React from "react";
import ReactDOM from "react-dom/client";
import { isStringOfLength } from "@pheaa/public-websites";

export default class ResponsiveVideoFragment extends HTMLElement {
	componentClass;
	fragmentId;
	height;
	src;
	title;
	type;
	variant;
	width;
	mountPoint;
	root;

	static get observedAttributes() {
		return [
			"class",
			"data-component-variant",
			"data-fragment-id",
			"data-height",
			"data-testing-id",
			"data-title",
			"data-video-type",
			"data-video-url",
			"data-width"
		];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		let
			numberValue,
			resultTrigger = false;

		if (isStringOfLength(name) &&
			isStringOfLength(newValue) &&
			this.root &&
			this.root.render) {
			// eslint-disable-next-line default-case
			switch (name) {
				case "class":
					this.componentClass = newValue;
					this.clearClasses();
					resultTrigger = true;
					break;

				case "data-component-variant":
					this.variant = newValue;
					resultTrigger = true;
					break;

				case "data-fragment-id":
					this.fragmentId = newValue;
					resultTrigger = true;
					break;

				case "data-height":
					numberValue = parseInt(newValue, 10);
					if (!Number.isNaN(numberValue)) {
						this.height = numberValue;
						resultTrigger = true;
					}
					break;

				case "data-title":
					this.title = newValue;
					resultTrigger = true;
					break;

				case "data-video-type":
					this.type = newValue;
					resultTrigger = true;
					break;

				case "data-video-url":
					this.src = newValue;
					resultTrigger = true;
					break;

				case "data-width":
					numberValue = parseInt(newValue, 10);
					if (!Number.isNaN(numberValue)) {
						this.width = numberValue;
						resultTrigger = true;
					}
					break;
			}
		}

		if (resultTrigger) {
			this.root.render(this.createVideo(this.packageProps()));
		}
	}

	clearClasses() {
		const classList = [...this.classList];

		classList.forEach(value => this.classList.remove(value));
	}

	connectedCallback() {
		this.componentClass = isStringOfLength(this.getAttribute("class")) ?
			this.getAttribute("class") : undefined;
		this.fragmentId = isStringOfLength(this.getAttribute("data-fragment-id")) ?
			this.getAttribute("data-fragment-id") : undefined;
		this.height = (isStringOfLength(this.getAttribute("data-height")) && !Number.isNaN(parseInt(this.getAttribute("data-height"), 10))) ?
			parseInt(this.getAttribute("data-height"), 10) : undefined;
		this.src = isStringOfLength(this.getAttribute("data-video-url")) ?
			this.getAttribute("data-video-url") : undefined;
		this.testId = isStringOfLength(this.getAttribute("data-testing-id")) ?
			this.getAttribute("data-testing-id") : undefined;
		this.title = isStringOfLength(this.getAttribute("data-title")) ?
			this.getAttribute("data-title") : undefined;
		this.type = isStringOfLength(this.getAttribute("data-video-type")) ?
			this.getAttribute("data-video-type") : undefined;
		this.variant = isStringOfLength(this.getAttribute("data-component-variant")) ?
			this.getAttribute("data-component-variant") : "iframe";
		this.width = (isStringOfLength(this.getAttribute("data-width")) && !Number.isNaN(parseInt(this.getAttribute("data-width"), 10))) ?
			parseInt(this.getAttribute("data-width"), 10) : undefined;

		if (this.src &&
			(this.variant === "iframe" || (this.variant === "html" && this.type))) {
			this.clearClasses();

			this.mountPoint = document.createElement("div");
			this.mountPoint.classList.add("pheaa-custom-element", "pheaa-custom-element__responsive-video");
			this.appendChild(this.mountPoint);

			this.root = ReactDOM.createRoot(this.mountPoint);
			this.root.render(this.createVideo(this.packageProps()));
		}
	}

	createVideo() {
		return React.createElement(this.component, this.packageProps());
	}

	packageProps() {
		return {
			className: this.componentClass,
			fragmentId: this.fragmentId,
			height: this.height,
			src: this.src,
			testId: this.testId,
			title: this.title,
			type: this.type,
			variant: this.variant,
			width: this.width
		};
	};
}