import { HelpText as PHEAAHelpText } from "@pheaa/public-websites";
import React from "react";
import { useTheme } from "@mui/material/styles";

const HelpText = props => {
	const
		theme = useTheme();

	const customPopperStyles = {
		"h4": { marginBottom: "1rem !important" }
	};

	return (
		<PHEAAHelpText customTheme={theme} {...props} popperStyles={customPopperStyles} />
	);
};

// Define propTypes;
HelpText.propTypes = PHEAAHelpText.propTypes;

// Export component as default;
export default HelpText;