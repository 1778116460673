import Alert from "./components/Alert/Alert";
import Announcement from "./components/Announcement/Announcement";
import BannerAd from "./components/BannerAd/BannerAd";
import CssBaseline from "@mui/material/CssBaseline";
import { GetTheme } from "./config/theme";
import HelpText from "./components/HelpText/HelpText";
import React from "react";
import ReactDOM from "react-dom/client";
import ResponsiveVideo from "./components/ResponsiveVideo/ResponsiveVideo";
import ResponsiveVideoFragment from "./customElements/ResponsiveVideoFragment/ResponsiveVideoFragment";
import { ThemeProvider } from "@mui/material/styles";
import { contentFragmentList } from "./config/content-fragment-list";
import { exclusionPatterns } from "./config/exclusionPatterns";
import { getEnvConfigFileName } from "./config/environment";
import reportWebVitals from "./reportWebVitals";
import { routeDataArray } from "./config/routeDataArray";
import {
	AlertFragment,
	AnnouncementFragment,
	BannerAdFragment,
	CustomDownloadCard,
	CustomHelpText,
	DownloadCard,
	ErrorBoundary,
	RouteManager,
	SiteConfigManager,
	contentConfig,
	contexts,
	customElementInit,
	getSiteConfig
} from "@pheaa/public-websites";

/* Import Project Styles */
import "./styles/index.scss";

// Set Content Fragment Map;
contentConfig.setContentFragmentMap(contentFragmentList);

// Define custom help text element;
customElementInit([
	{ name: "pheaa-alert", component: Alert, customElement: AlertFragment },
	{ name: "pheaa-announcement", component: Announcement, customElement: AnnouncementFragment },
	{ name: "pheaa-banner-ad", component: BannerAd, customElement: BannerAdFragment },
	{ name: "pheaa-download-card", component: DownloadCard, customElement: CustomDownloadCard },
	{ name: "pheaa-help-text", component: HelpText, customElement: CustomHelpText },
	{ name: "pheaa-responsive-video", component: ResponsiveVideo, customElement: ResponsiveVideoFragment }
]);

const environmentConfigFile = getEnvConfigFileName();
const root = ReactDOM.createRoot(document.getElementById("root"));

getSiteConfig(`/${environmentConfigFile}`).then(config => {
	root.render(
		<React.StrictMode>
			<SiteConfigManager config={config}>
				<ErrorBoundary>
					<contexts.RouteDataContext.Provider value={routeDataArray}>
						<ThemeProvider theme={GetTheme()}>
							<CssBaseline />
							<RouteManager data={routeDataArray} excludedPaths={exclusionPatterns} />
						</ThemeProvider>
					</contexts.RouteDataContext.Provider>
				</ErrorBoundary>
			</SiteConfigManager>
		</React.StrictMode>
	);
});

root.render(
	<React.StrictMode>
		Initializing...
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
