import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Announcement as PHEAAAnnouncement, isStringOfLength } from "@pheaa/public-websites";

const merge = require("deepmerge");

const Announcement = props => {

	const {
		icon = {},
		initialTheme = {},
		styles = {},
		type = "",
		...rest
	} = props;

	const theme = merge(initialTheme, useTheme());

	const customIcon = merge(
		type === "lightBulb" ?
			{
				alt: "Lightbulb icon",
				src: `${process.env.PUBLIC_URL}/pheaa/images/icons/lightbulb-icon.svg`,
				height: "10rem",
				width: "auto"
			}
			:
			type === "megaPhone" ?
				{
					alt: "Alert icon",
					src: `${process.env.PUBLIC_URL}/pheaa/images/icons/megaphone-icon.svg`,
					height: "10rem",
					width: "auto"
				} :
				type === "gradCap" ?
					{
						alt: "Graduation cap icon",
						src: `${process.env.PUBLIC_URL}/pheaa/images/icons/yellow-graduation-cap-icon.svg`,
						height: "10rem",
						width: "auto"
					} :
					// default icon
					type === "alert" || !isStringOfLength(type) ?
						{
							alt: "Alert icon",
							src: `${process.env.PUBLIC_URL}/pheaa/images/icons/alert-icon-new.svg`,
							height: "8rem",
							width: "auto"
						} : {}
		, icon);

	const tanStyles = {
		backgroundColor: "#FFF7E5",
		".Announcement__icon": {
			marginBottom: "0",
			marginRight: "1.4rem"
		},
		".Announcement__header,h2": {
			letterSpacing: "0",
			textTransform: "none",
			fontSize: "2.6rem",
			color: "#282F3C"
		},
		"h3": {
			letterSpacing: "0",
			textTransform: "none",
			fontFamily: theme.fontFamilies.semiBold,
			fontSize: "2rem"
		},
		".button--isCTALinkWithArrow": { textAlign: { xs: "center", md: "left" } },
		"div > h2": {
			marginTop: { xs: "2rem", md: "0" },
			fontFamily: theme.fontFamilies.semiBold
		}
	};

	const customStyles = merge.all([
		{
			"h1,h2,h3,h4,h5,h6": {
				fontSize: "2.2rem !important",
				lineHeight: "1.4",
				marginBottom: "1rem",
				textTransform: "none !important"
			},
			".Announcement__content": {
				padding: {
					xs: "1.6rem 0 0",
					md: "1.6rem 1.6rem 0 1.6rem"
				}
			},
			".Announcement__icon": {
				margin: {
					xs: "0 !important",
					md: "0 1.4rem 0 0 !important"
				}
			}
		},
		(type === "gradCap" || type === "megaPhone" || type === "lightBulb" || type === "alert" ? tanStyles : {}),
		styles
	]);


	return (
		<PHEAAAnnouncement customTheme={theme} icon={customIcon} styles={customStyles} {...rest} />
	);
};

Announcement.propTypes = {
	...PHEAAAnnouncement.propTypes,
	/** Icon image properties (Default Value: {}) */
	icon: PropTypes.shape({
		/** Alternative Text */
		alt: PropTypes.string,
		/** Image path */
		src: PropTypes.string,
		/** Image height */
		height: PropTypes.string,
		/** Image width */
		width: PropTypes.string
	}),
	/** Type prop to set icon and styles (Default Value: "blueAnnouncement") */
	type: PropTypes.oneOf(["", "alert", "gradCap", "lightBulb", "megaPhone"])
};

// Export component as default;
export default Announcement;