import React from "react";
import { useTheme } from "@mui/material/styles";
import {
	ImportedContentFragment as PHEAAImportedContentFragment,
	isStringOfLength
} from "@pheaa/public-websites";

const ImportedContentFragment = props => {
	const
		merge = require("deepmerge"),
		theme = useTheme(),
		{ component, styles = {}, options = {}, ...rest } = props,
		DomPurifyAdditions = merge({
			ADD_TAGS: ["pheaa-responsive-video"]
		}, options);

	let returnElem;

	if (isStringOfLength(component)) {

		const customStyles = merge({
			".Accordion--isDetails p": { marginBottom: "1.6rem" }, // Add to general?
			".Mui-expanded.MuiAccordion-gutters": {
				margin: "0 !important"
			},
			".MuiAccordionSummary-gutters": {
				backgroundColor: "#FFFFFF !important"
			},
			".MuiAccordion-root": {
				borderBottom: "0.1rem #D3DBDB solid",
				margin: "0",
				".MuiAccordionSummary-root": { padding: "2rem 1.6rem !important" },
				"::before": {
					display: "none"
				}
			}
		}, styles);

		returnElem = <PHEAAImportedContentFragment {...rest} component={component} customTheme={theme} options={DomPurifyAdditions} styles={customStyles} />;
	} else {
		returnElem = <PHEAAImportedContentFragment {...rest} options={DomPurifyAdditions} />;
	}

	return returnElem;
};

ImportedContentFragment.propTypes = PHEAAImportedContentFragment.propTypes;

// Export component as default;
export default ImportedContentFragment;