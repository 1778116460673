import React from "react";
import { useTheme } from "@mui/material/styles";
import { BannerAd as PHEAABannerAd, isStringOfLength } from "@pheaa/public-websites";

const merge = require("deepmerge");

const BannerAd = props => {

	const theme = useTheme();

	const {
		type,
		roundedEdges = false,
		customCardStyles = {},
		variant: passedVariant,
		...rest
	} = props;

	const lightBlueAdIconSx = {
		display: "flex",
		justifyContent: "center",
		padding: { xs: "0 2.5rem", md: "0 5rem" },
		".bannerAd__inner": {
			justifyContent: "center",
			alignItems: { xs: "center", sm: "center", md: "flex-start" },
			padding: "3.6rem 0",
			gap: { xs: "1.3rem", sm: "4.5rem", md: "5.4rem" },
			margin: "0",
			flexDirection: { xs: "column", sm: "row" }
		},
		".bannerAd__content": {
			paddingLeft: "0 !important", paddingTop: "0",
			minWidth: { sm: "33rem", md: "48rem" },
			textAlign: { xs: "center", sm: "left" },
			"ul": { textAlign: "left" },
			"h2": { textAlign: { xs: "center", sm: "left !important" }, color: "#004569 !important" },
			".MuiButton-root": { margin: "2rem 0 0" },
			".button--isPrimary": { margin: { xs: "calc(2rem - 1.6rem) 1rem 0 !important", sm: "calc(2rem - 1.6rem) 1rem 0 0 !important" }, display: "inline-flex" }
		},
		".bannerAd__inner .bannerAd__image": {
			flexBasis: { xs: "10rem", sm: "15rem" },
			width: { xs: "10rem", sm: "15rem" },
			height: { xs: "10rem", sm: "15rem" },
			minWidth: { xs: "10rem", sm: "15rem" },
			minHeight: { xs: "10rem", sm: "15rem" },
			maxHeight: { xs: "10rem", sm: "15rem" },
			maxWidth: { xs: "10rem", sm: "15rem" },
			backgroundSize: "contain"
		},
		".bannerAd__button-container": {
			marginTop: "2rem",
			gap: "1.5rem",
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: { xs: "center", sm: "flex-start" },
			".MuiButton-root,a,.MuiButton-ctalinkWithArrow": {
				margin: "0 !important"
			},
			"a": {
				marginTop: "0 !important"
			}
		}
	};

	const lightGreenAdIconSx = merge(lightBlueAdIconSx, {
		backgroundColor: "rgb(233, 245, 224)",
		".bannerAd__content h2": {
			color: "#0D2800 !important"
		},
		".bannerAd__button-container": {
			marginTop: "2rem",
			gap: "1.5rem",
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: { xs: "center", sm: "flex-start" },
			".MuiButton-root,.MuiButton-ctalinkWithArrow,a": {
				margin: "0 !important"
			}
		}
	});

	const pdfWithButtonSx = {
		".bannerAd__inner .bannerAd__content": {
			flexDirection: "column",
			alignItems: "flex-start",
			"> div": {
				maxWidth: "100%",
				// last 2 p tags should always be links for this to work.
				"p:last-of-type,p:nth-last-of-type(2)": {
					display: "inline-flex"
				},
				"h2": { color: "#004569 !important" },
				".button--isPrimary": {
					margin: "0 1rem 0 0"
				},
				"p:last-of-type": {
					marginTop: { xs: "0", sm: "1.6rem" }
				}
			}
		}
	};

	const greenWithAirplanesSx = {
		backgroundColor: "rgb(233, 245, 224)",
		backgroundImage: `url("${process.env.PUBLIC_URL}/pheaa/images/icons/contact-paper-planes-bg.svg")`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: { xs: "right", md: "center" },
		backgroundSize: "cover",
		"h2": { color: "#0D2800 !important" },
		".bannerAd__inner > .bannerAd__content > div": {
			maxWidth: "100%",
			"ul": { textAlign: "left" }
		}
	};

	const lightGreenAdSx = {
		".bannerAd__inner .bannerAd__content": {
			paddingLeft: "0 !important", paddingTop: "0",
			minWidth: { sm: "33rem", md: "48rem" },
			maxWidth: "100%",
			textAlign: { xs: "center", sm: "left" },
			"ul": { textAlign: "left" },
			"h2": { textAlign: { xs: "center", sm: "left !important" }, color: "#0D2800 !important" },
			".MuiButton-root": { margin: "2rem 0 0" },
			".button--isPrimary": { margin: { xs: "calc(2rem - 1.6rem) 1rem 0 !important", sm: "calc(2rem - 1.6rem) 1rem 0 0 !important" }, display: "inline-flex" }
		},
		backgroundColor: "rgb(233, 245, 224)",
		backgroundSize: "cover",
		"h2, h3": { color: "#0D2800 !important" }
	};

	const lightBlueAdSx = {
		".bannerAd__inner .bannerAd__content": {
			maxWidth: "100%",
			textAlign: { xs: "center", md: "left" },
			"ul": { textAlign: "left" },
			"h2, h3": { color: "#004569 !important" },
			".MuiButton-root": { margin: { xs: "2rem 0 0", md: "0" } }
		}
	};

	const overlayAdSx = {
		backgroundImage: `url(${process.env.PUBLIC_URL}/pheaa/images/homepage/email-optin.png)`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "50rem auto",
		backgroundPosition: { xs: "top center", md: "-14rem 0" },
		borderRadius: "0.5rem",
		marginBottom: "-3rem",
		position: "relative",
		".bannerAd__content": {
			paddingLeft: { xs: "0", md: "15rem" },
			paddingTop: { xs: "15rem", md: "0rem" },
			"h2,h3": {
				color: "#004569"
			},
			".MuiButton-root": {
				marginLeft: { xs: "0", md: "2rem !important" }
			}
		}
	};

	// if passed a variant, set to passed variant, if blueAd, set to imageSplitAd, default variant is splitAd
	let customStyle, customVariant;

	switch (type) {
		case "lightBlueAd":
			customStyle = lightBlueAdSx;
			customVariant = "splitAd";
			break;

		case "overlayAd":
			customStyle = overlayAdSx;
			customVariant = "splitAd";
			break;

		case "lightBlueWithIcon":
			customStyle = lightBlueAdIconSx;
			customVariant = "imageSplitAd";
			break;

		case "lightGreenWithIcon":
			customStyle = lightGreenAdIconSx;
			customVariant = "imageSplitAd";
			break;

		case "pdfWithButton":
			customStyle = pdfWithButtonSx;
			customVariant = "splitAd";
			break;

		case "greenWithAirplanes":
			customStyle = greenWithAirplanesSx;
			customVariant = "splitAd";
			break;

		case "lightGreenAd":
			customStyle = lightGreenAdSx;
			customVariant = "splitAd";
			break;

		default:
			customStyle = {};
			customVariant = "splitAd";
			break;
	}

	if (isStringOfLength(passedVariant)) {
		customVariant = passedVariant;
	}

	return (
		<PHEAABannerAd variant={customVariant} customTheme={theme} customCardStyles={merge.all([
			customStyle,
			{
				borderRadius: roundedEdges ? ".5rem" : "initial"
			},
			customCardStyles
		])} {...rest} />
	);
};

BannerAd.propTypes = PHEAABannerAd.propTypes;

// Export component as default;
export default BannerAd;