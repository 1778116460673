import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Alert as PHEAAAlert, isObject } from "@pheaa/public-websites";

const
	merge = require("deepmerge"),

	Alert = props => {

		const { customContainerStyles = {
			"h1,h2,h3,h4,h5,h6": {
				fontSize: "2.2rem !important",
				lineHeight: "1.2",
				marginBottom: "1rem",
				textTransform: "none !important"
			},
			"p+p": {
				marginTop: "1.6rem"
			}
		}, type = "", customIcon, ...rest } = props;

		const theme = useTheme();

		const containerStyles = merge(
			type === "tanAlert" ? {
				backgroundColor: "#FDEFDD"
			} : type === "blueAlert" ? {
				backgroundColor: "rgb(221, 243, 251)"
			} : {}
			, customContainerStyles);

		let icon;

		if (isObject(customIcon)) {
			icon = customIcon;
		} else if (type === "tanAlert") {
			icon = (<Typography
				component="img"
				src={`${process.env.PUBLIC_URL}/pheaa/images/icons/alert-icon.svg`} alt="Alert icon"
				sx={{ height: "3.5rem", width: "auto" }}
			/>);
		} else if (type === "blueAlert") {
			icon = <ErrorIcon sx={{ color: "#007DAD", height: "3.6rem", width: "3.6rem" }} />;
		} else {
			icon = <ErrorIcon sx={{ color: "#CC0000", height: "3.6rem", width: "3.6rem" }} />;
		}

		return (
			<PHEAAAlert customTheme={theme} customContainerStyles={containerStyles} customIcon={icon} {...rest} />
		);
	};

Alert.propTypes = PHEAAAlert.propTypes;

// Export component as default;
export default Alert;