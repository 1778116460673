import { Box } from "@mui/material";
import ImportedContentFragment from "../ImportedContentFragment/ImportedContentFragment";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { isArray, isArrayOfLength, isString, isStringOfLength } from "@pheaa/public-websites";

const
	merge = require("deepmerge"),

	GetTestIds = seed => {
		const isValid = isStringOfLength(seed);

		return {
			component: isValid ? seed : undefined,
			description: isValid ? `${seed}__description` : undefined,
			iframe: isValid ? `${seed}__iframe` : undefined,
			native: isValid ? `${seed}__native` : undefined,
			source: isValid ? `${seed}__source` : undefined,
			wrapper: isValid ? `${seed}__wrapper` : undefined
		};
	},

	ResponsiveVideo = props => {
		const
			{
				className,
				customStyles = {},
				fragmentId,
				height = 315,
				src,
				srcArray,
				testId,
				title,
				type,
				variant = "iframe",
				width = 560,
				...rest
			} = props,
			compiledClassnames = classNames({
				"ResponsiveVideo": true,
				[`${className}`]: className
			}),
			defaultTitle = variant === "iframe" ? "YouTube video player" : undefined,
			descriptionId = isStringOfLength(fragmentId) ? `id_${fragmentId}` : undefined,
			testIds = GetTestIds(testId),
			styles = merge({
				marginTop: "1rem",
				".ResponsiveVideo__container": {
					margin: "0 auto 4rem",
					width: {
						xs: "100%",
						md: "75%"
					},
					".ResponsiveVideo__wrapper": {
						overflow: "hidden",
						paddingTop: `${(height / width) * 100}%`,
						position: "relative",
						width: "100%",
						"iframe,video": {
							borderWidth: 0,
							bottom: "0",
							left: "0",
							height: "100%",
							position: "absolute",
							right: "0",
							top: "0",
							width: "100%"
						}
					}
				}
			}, customStyles);

		return (
			<Box className={compiledClassnames} data-testid={testIds.component} sx={styles}>
				<Box className="ResponsiveVideo__container">
					<Box className="ResponsiveVideo__wrapper" data-testid={testIds.wrapper}>
						{
							variant === "iframe" &&
							isStringOfLength(src) &&
							<iframe
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen="allowFullScreen"
								aria-describedby={descriptionId}
								data-testid={testIds.iframe}
								title={isStringOfLength(title) ? title : defaultTitle}
								referrerPolicy="strict-origin-when-cross-origin"
								src={src}
								{...rest}></iframe>
						}
						{
							variant === "html" &&
							((isStringOfLength(src) && isStringOfLength(type)) || isArray(srcArray)) &&
							<video
								controls
								data-testid={testIds.native}
								title={isStringOfLength(title) ? title : defaultTitle}
								{...rest}>
								{
									isStringOfLength(src) && isStringOfLength(type) &&
									<source
										data-testid={testIds.source}
										src={src}
										type={type} />
								}
								{
									isArray(srcArray) &&
									isArrayOfLength(srcArray.filter(video => isStringOfLength(video.src) && isString(video.type))) &&
									srcArray.filter(video => isStringOfLength(video.src) && isString(video.type)).map(video => (
										<source
											data-testid={testIds.source}
											key={video.src}
											src={video.src}
											type={video.type} />
									))
								}
								Your browser does not support the video tag.
							</video>
						}
					</Box>
				</Box>
				{
					isStringOfLength(fragmentId) &&
					<Box
						data-testid={testIds.description}
						id={descriptionId}>
						<ImportedContentFragment id={fragmentId} />
					</Box>
				}
			</Box>
		);
	};

// Define propTypes;
ResponsiveVideo.propTypes = {
	// Custom Styles Object;
	customStyles: PropTypes.object,
	// Height of video, in pixels;
	height: PropTypes.number,
	// Video src, required;
	src: PropTypes.string,
	// Array of source/type combintations for native video element;
	srcArray: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired
	})),
	// Test ID;
	testId: PropTypes.string,
	// Title of video;
	title: PropTypes.string,
	// Video type, required if using HTML variant;
	type: PropTypes.string,
	// Future feature - switch to change video render mode;
	variant: PropTypes.oneOf([
		"iframe", "html"
	]),
	// Width of video, in pixels;
	width: PropTypes.number
};

// Export component as default;
export default ResponsiveVideo;
export { GetTestIds };